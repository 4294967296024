import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "content", "arrow" ]

  connect() {
    this.contentTarget.classList.add("collapse")
  }

  toggle(event) {
    event.preventDefault()

    this.contentTarget.classList.toggle("show")
    this.arrowTarget.classList.toggle("flip")
  }
}
