import { Controller } from "stimulus"

export default class extends Controller {

  dragstart(event) {
    event.dataTransfer.setData("application/drag-key", event.target.dataset.recordId);
    event.dataTransfer.effectAllowed = "move";
  }

  dragover(event) {
    event.preventDefault();
    return true
  }

  dragenter(event) {
    event.preventDefault();
  }

  drop(event) {
    let dropTarget;

    let data = event.dataTransfer.getData("application/drag-key");

    if (event.target.tagName.toLowerCase() === "td") {
      dropTarget = event.target.parentElement;
    } else {
      dropTarget = event.target;
    }

    const draggedItem = this.element.parentElement.querySelector(`[data-record-id='${data}']`);
    const positionComparison = dropTarget.compareDocumentPosition(draggedItem);

    if (positionComparison & 4) {
      dropTarget.insertAdjacentElement('beforebegin', draggedItem);
    } else if (positionComparison & 2) {
      dropTarget.insertAdjacentElement('afterend', draggedItem);
    }

    this.savePosition(draggedItem);

    event.preventDefault();
  }

  dragend(event) {
  }

  savePosition(draggedItem) {
    const url = draggedItem.dataset.sortUrl
    const parent = draggedItem.parentElement;

    let positions = []

    for (let el of parent.children) {
      positions.push(el.dataset.recordId)
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({positions: positions})
    })
  }
}
